import axios from 'axios'
import { MainVue } from '@/main'
import { baseUrl } from '../../config'

const instance = axios.create({
  // 在这里引入基础url，后面的接口拼接在baseURL
  baseURL: baseUrl,
  timeout: 100000
})

instance.interceptors.request.use(config => {
  config.headers.Authorization = sessionStorage.getItem('token')
  return config
}, err => {
  return Promise.reject(err)
})

instance.interceptors.response.use(res => {
  // 如果没有登录或者token超时进入登录页面
  if (res.data.code === 201) {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('menu')
    MainVue.$router.push('/login')
  }
  return res.data
}, err => {
  return Promise.reject(err)
})

export default instance
